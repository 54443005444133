@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto+Flex:opsz,wght@8..144,500&display=swap");

* {
  font-family: "Poppins", sans-serif !important;
  /* font-family: 'Roboto Flex', sans-serif; */
  letter-spacing: 0.5px;
}

.activename {
  color: #000;
  border-bottom: 3px var(--ion-color-primary) solid;
  font-size: var(--font-size-20);
}

.loginForm h2 {
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-18);
}

.bg {
  background-image: url("../assets/images/66.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-attachment: fixed;
  height: 100%;
}

.edit #ion-sel-0-lbl {
  width: auto !important;
}

.slideshow {
  list-style-type: none;
}

/** SLIDESHOW **/
.slideshow,
.slideshow:after {
  top: -16px;
  /*Not sure why I needed this fix*/
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  z-index: 0;
}

.slideshow li span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  animation: imageAnimation 50s linear infinite 0s;
}

.slideshow li:nth-child(1) span {
  background-image: url("../assets/images/1.jpg");
}

.slideshow li:nth-child(2) span {
  background-image: url("../assets/images/1.jpg");
  animation-delay: 6s;
}

.slideshow li:nth-child(3) span {
  background-image: url("../assets/images/1.jpg");
  animation-delay: 12s;
}

.slideshow li:nth-child(4) span {
  background-image: url("../assets/images/1.jpg");
  animation-delay: 18s;
}

.slideshow li:nth-child(5) span {
  background-image: url("../assets/images/1.jpg");
  animation-delay: 24s;
}

/* input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
} */

/* Style the custom checkbox when it's checked */
input[type="checkbox"]:checked {
  background-color: rgb(255, 0, 0);
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }

  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes titleAnimation {
  0% {
    opacity: 0;
  }

  8% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  19% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.no-cssanimations .cb-slideshow li span {
  opacity: 1;
}

.logo {
  background-image: url("../assets/images/logo.png");
  height: 100px;
  width: 300px;
  margin: 23% auto;
}

.otp-lock {
  background-image: url("../assets/images/otp.png");
  height: 90px;
  width: 125px;
  margin-top: 100px;
  text-align: center;
  margin: 18px auto;
}

.tab {
  border-radius: 40px 40px 0px 0px;
}

.btnFill ion-button::part(native) {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-light) !important;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  box-shadow: 0px 14px 13px rgba(255, 90, 0, 0.18);
  padding: 22px 20px;
  text-transform: capitalize;
  font-size: var(--font-size-17);
  font-weight: var(--font-weight-600);
}

.backBtn ion-button::part(native) {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-light);
  border-radius: 10px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  padding: 18px 8px;
  text-transform: capitalize;
  font-size: var(--font-size-16);
  box-shadow: none;
}

.btnOutline ion-button::part(native) {
  --background: rgba(239, 66, 59, 0.2) !important;
  color: var(--ion-color-dark) !important;
  border: 1px var(--ion-color-primary) solid !important;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  box-shadow: none;
  text-transform: capitalize;
}

.p-20 {
  padding: 20px;
  background-color: var(--ion-color-light);
  /* padding-left: 0px; */
}

.ml-10 {
  margin-left: 10px;
}

.mtb-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-0 {
  margin: 0px;
}

.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.highlight {
  color: #e58005;
}

.text1 {
  font-size: 16px;
  line-height: 22px;
  color: #3e3e3e;
}

.hr-text {
  width: 50px;
  margin: auto;
  background: var(--ion-color-light);
  margin-top: -18px;
  text-align: center;
  color: #000;
  font-weight: 600;
}

.hr-border-red {
  height: 1px;
  width: 100%;
  margin-top: -18px;
  background: linear-gradient(
    to right,
    var(--ion-color-light) 0%,
    red 26%,
    red 52%,
    red 75%,
    var(--ion-color-light) 100%
  );
  margin: 2.5px 0px;
}

.hr-border-yellow {
  height: 1px;
  width: 100%;
  margin-top: -18px;
  background: linear-gradient(
    to right,
    var(--ion-color-light) 0%,
    #ffcc00 26%,
    #ffcc00 52%,
    #ffcc00 75%,
    var(--ion-color-light) 100%
  );
  margin: 2.5px 0px;
}

.hr-border-green {
  height: 1px;
  width: 100%;
  margin-top: -18px;
  background: linear-gradient(
    to right,
    var(--ion-color-light) 0%,
    green 26%,
    green 52%,
    green 75%,
    var(--ion-color-light) 100%
  );
  margin: 2.5px 0px;
}

.hr-border-blue {
  height: 1px;
  width: 100%;
  margin-top: -18px;
  background: linear-gradient(
    to right,
    var(--ion-color-light) 0%,
    blue 26%,
    blue 52%,
    blue 75%,
    var(--ion-color-light) 100%
  );
  margin: 2.5px 0px;
}

.label-stacked.sc-ion-label-ios-h {
  color: #999;
}

.checkbox-icon {
  border-radius: 20% !important;
}

.color-primary {
  color: var(--ion-color-primary) !important;
}

/* .f-16 {
  font-size: var(--font-size-16) !important;
} */

/* .f-20 {
  font-size: var(--font-size-20) !important;
} */

/* .f-30 {
  font-size: var(--font-size-30) !important;
} */
/* .f-12 {
  font-size: var(--font-size-12);
} */

/* .f-14 {
  font-size: var(--font-size-14) !important;
} */

/* .f-18 {
  font-size: var(--font-size-18) !important;
} */

/* .f-22 {
  font-size: var(--font-size-22);
} */
/* 
.border-bottom-none .item-inner {
  border-bottom: none !important;
} */

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.bottom-text {
  text-align: center;
  font-size: var(--font-size-16);
  color: #3e3e3e;
}

.bottom-text h4 {
  margin: 0px;
  line-height: 25px;
}

.errorText {
  font-size: 14px;
  color: red;
  font-weight: 500;
  margin-left: 12px;
}

.otpInput {
  font-size: 36px;
  text-align: center;
  font-weight: 600;
}

.otpSection {
  display: flex;
  justify-content: center;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-3 {
  font-weight: 300;
}

/* 
ion-tab-button::part(native) {
  color: #000;
  opacity: 0.7;
} */
ion-radio {
  /* --border-radius: 4px; */
  /* --inner-border-radius: 4px; */

  --color: #acacac;
  --color-checked: #ca3832;
}

ion-radio.ios {
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 50px;
  float: left;
}

.radio-checked.ios {
  border-color: #6815ec;
}

.check-round {
  --border-radius: 10px;
}

.border-none {
  --inner-border-width: 0 0 0 0;
}

/* Dashboard css */
.danger {
  color: var(--ion-color-danger);
}

.progress-height {
  height: 7px !important;
  border-radius: 10px;
  margin: 12px 0px;
}

.add-text {
  position: absolute;
  right: -10px;
  background-color: var(--ion-color-primary);
  padding: 3px 15px 3px 10px;
  font-size: var(--font-size-12) !important;
  color: #fff !important;
  border-radius: 5px 0px 0px 5px;
  top: 5px;
  /* padding-right: 15px; */
}

.font-w-600 {
  font-weight: var(--font-weight-600);
}

.font-w-400 {
  font-weight: var(--font-weight-400) !important;
}

.people-icon {
  border: 1px #ddd solid;
  border-radius: 50px;
  margin-top: -15px;
  position: relative;
  width: 36px;
  height: 36px;
}

.user-icon-bg {
  background-image: url("../assets/images/users-icon.png");
  width: 17px;
  height: 17px;
  background-size: cover;
  margin: 17% auto;
}

.ion-badge {
  position: absolute;
  right: -5px;
  bottom: 4px;
  border-radius: 25px;
  padding: 0px;
  width: 12px;
  height: 12px;
}

.search-icon {
  border: 5px solid rgb(255, 198, 205);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 5px;
}

.search-tab-btn ion-fab-button::part(native) {
  box-shadow: none !important;
  background: var(--ion-color-primary) !important;
}

.box-profile {
  /* background-image: url('../assets/images/blank_user.png'); */
  background-size: auto;
  background-position: center;
  min-height: 375px;
  /* background-image: url(https://cdn-icons-png.flaticon.com/512/1946/1946429.png); */
  background-repeat: no-repeat;
  background-color: #f2f2f2;
}

.details-box {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  position: absolute;
  width: -webkit-fill-available;
  bottom: 3%;
  padding: 6px 1px 5px 1px;
  margin: 0px 10px;
}

.detailDown ion-item::part(native) {
  padding-left: 0px !important;
  --border-style: none !important;
  background: none !important;
}

.detailDown .item-inner {
  padding-right: 6px !important;
}

.detailUp ion-item::part(native) {
  padding-left: 6px !important;
  --border-style: none !important;
  background: none !important;
}

.detailUp ion-item::part(native) .item-inner {
  padding-right: 5px !important;
  --padding: 0;
}

.heart-icon {
  background-color: var(--ion-color-light);
  padding: 5px;
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 5px;
  font-size: 24px;
}

.heart-icon2 {
  background-color: #f2f2f2;
  padding: 3px 5px;
  text-align: center;
  width: 35px;
  height: 33px;
  right: 10px;
  top: 10px;
  border-radius: 5px;
  font-size: 24px;
}

.btn {
  font-size: var(--font-size-12) !important;
  text-transform: capitalize;
  height: 40px;
}

.dark {
  color: var(--ion-color-dark);
}

.b-right {
  border-right: 1px solid var(--ion-color-muted);
}

.live {
  height: 5px;
  width: 5px;
  color: green;
  padding: 2px;
}

.text-capi {
  text-transform: capitalize;
  font-weight: var(--font-weight-500);
}

.secondary {
  color: rgb(148, 148, 148);
}

.white {
  /* color: var(--ion-color-light); */
  transform: rotate(315deg);
  margin-top: -4px;
  margin-left: -3px;
  margin-right: 3px;
}

.bg-none {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}

.side-box {
  width: 55%;
}

.b-radius-10 {
  border-radius: 10px;
}

.folded-hands {
  background-image: url("../assets/images/folded-hands.png");
  height: 20px;
  width: 20px;
  background-size: 100%;
  float: left;
  margin-left: 7px;
  margin-top: 1px;
}

.f-left {
  float: left;
}

.bell {
  text-align: center;
  border: 1px solid var(--ion-color-light);
  border-radius: 10px;
  padding: 10px;
}

.rounded-pill {
  border-radius: 25px;
}

.ml-10 {
  margin-left: 10px;
}

.m-left-10 {
  margin-left: -10px;
}

.line-div {
  width: 20%;
  height: 4px;
  background-color: var(--ion-color-primary);
  margin: auto;
  margin-bottom: -19px;
  margin-top: 13px;
  border-radius: 15px;
}

/* surbhi css */

.languageDropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.languageDropdown ion-select {
  border-radius: 10px;
  background-color: var(--ion-color-light);
  margin: 10px 10px 0px 0px;
  color: var(--ion-color-dark);
}

.languageDropdown ion-select::part(placeholder) {
  color: var(--ion-color-dark);
  opacity: 1;
}

.languageDropdown ion-select::part(icon) {
  color: var(--ion-color-primary);
  opacity: 1;
}

.tab ion-tab-button {
  max-width: 100%;
  color: var(--ion-color-dark);
  /* opacity: 0.7; */
}

.loginForm ion-item::part(native) {
  border: none;
}

.user-list ion-item::part(native) {
  --border-color: white;
}

ion-item::part(native) {
  --border-style: none;
}

input {
  font-weight: 600 !important;
  /* color: var(--ion-color-muted) !important; */
}

.labelItem {
  /* color: var(--ion-color-dark) !important; */
  color: #640000 !important;
  font-size: var(--font-size-15) !important;
  font-weight: var(--font-weight-600) !important;
  line-height: 24px !important;
}

.label {
  font-size: var(--font-size-20) !important;
  color: var(--ion-color-dark) !important;
  font-weight: var(--font-weight-600);
}

.Color-Theme {
  color: #640000 !important;
}

.Color-Placeholder::placeholder {
  color: #640000 !important;
}

.heading {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-600);
  color: var(--ion-color-dark);
}

.header-md {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.header-md::after {
  background-image: none !important;
}

.card {
  background: var(--ion-color-light);
  box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.12);
  border-radius: var(--border-radius-medium) !important;
  border: none !important;
  margin: 0px 5px;
}

.subscriptionCardParent {
  background: var(--ion-color-light);
  box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.12);
  border-radius: var(--border-radius-medium) !important;
}

.subscriptionBtn {
  position: fixed;
  bottom: 20px;
  z-index: 102;
  width: 100%;
}

.subscriptionDesc {
  color: #000;
  line-height: 30px;
}

.bottomContainer {
  border-radius: var(--border-radius-largest);
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
}

.bottomCover {
  background-color: var(--ion-color-light);
  /* position: absolute; */
  /* bottom: 0px; */
  padding: 20px;
  border-top-left-radius: var(--border-radius-largest);
  border-top-right-radius: var(--border-radius-largest);
  width: 100%;
  z-index: 2;
}

.UserListBody .smallPrimaryBtn {
  box-shadow: none;
}

.UserListBody ion-item::part(native) {
  --border-color: var(--ion-color-light);
}

.UserListBody img {
  border-radius: var(--border-radius-lighter) !important;
}

.UserListBody .userName {
  font-size: var(--font-size-15);
  font-weight: var(--font-weight-600);
}

/* .searchBtnFab {} */

/* footer */
.footer {
  background-color: var(--ion-color-light);
  bottom: 0px;
  width: 100%;
  left: 0px;
  padding: 5px 15px 10px;
  border-radius: 30px 30px 0px 0px;
  -webkit-box-shadow: 1px -2px 5px 2px rgba(222, 222, 222, 1);
  -moz-box-shadow: 1px -2px 5px 2px rgba(222, 222, 222, 1);
  box-shadow: 1px -2px 5px 2px rgba(222, 222, 222, 1);
}

.search-tab-btn {
  margin-top: -38px !important;
  background-color: var(--ion-color-light);
  border-radius: 50%;
  -webkit-box-shadow: 0px -1px 1px rgba(201, 201, 201, 0.63);
  -moz-box-shadow: 0px -1px 1px rgba(201, 201, 201, 0.63);
  box-shadow: 0px -1px 1px rgba(201, 201, 201, 0.63);
  width: 95px !important;
  height: 94px;
}

.ril_outer {
  background-color: rgba(0, 0, 0) !important;
}

/* end footer */
/* ion-progress-bar::part(progress) {
  opacity: 0.25;
} */

/* end surbhi css */

ion-chip.ion-color.ion-color-medium.md.ion-activatable {
  min-width: max-content;
}

textarea.native-textarea.sc-ion-textarea-md {
  border: revert;
}

@media (min-width: 640px) {
  .dasktop-signup {
    /* width: 40%; */
    margin: 0px auto;
  }

  /* Dashboard */
  .side-box {
    width: 15%;
  }

  .box-profile {
    width: 30%;
    float: left;
  }

  .box-new-profile {
    width: 30%;
    float: left;
  }

  .footer-box {
    /* width: 50%; */
    margin: 0px auto;
  }

  .search-tab-btn {
    /* margin-top: -60px; */
    height: 95px;
    max-width: 95px !important;
  }

  .tab {
    /* width: 50%; */
    margin: 0px auto;
  }

  /* Dashboard */
}

/* @media (min-width: 640px) {
  .slide-banner {
    /* padding: 80px; */
/* height: 600px;
  }

  .bannersClass {
    object-fit: contain;
    border-radius: 15%;
  } */
/* } */

.outline-btn {
  text-transform: capitalize;
  height: 35px;
  font-weight: 500;
  --border-width: 1px;
  min-width: 100px;
}

.live {
  width: 10px;
  height: 10px !important;
  background-color: green;
  padding: 5px;
  border-radius: 50px;
  position: absolute;
  top: 3px;
  margin-left: 5px;
}

.pl-12 {
  padding-left: 12px;
}

p {
  letter-spacing: 0.5px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.3px;
}

/* Dashboard css */

/* Profile */
.bg-danger {
  background-color: var(--ion-color-primary) !important;
  border-radius: 0px 0px 50% 50% !important;
  padding-bottom: 300px !important;
}

.m-auto {
  margin: 0px auto;
}

.profile {
  border: 5px var(--ion-color-light) solid;
  padding: 5px;
  width: 100px;
  height: 100px;
}

.light {
  color: var(--ion-color-light);
}

.mt-300 {
  margin-top: -300px !important;
}

.b-danger {
  border: 2px #ff4961 solid;
  border-radius: 15px;
  height: 100%;
}

.pro-list-text {
  padding-left: 10px !important;
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-600) !important;
  color: var(--ion-color-secondary);
  letter-spacing: 0.8px;
}

.border-none {
  border: none !important;
}

.border-r-15 {
  border-radius: 15px !important;
}

.pro-box {
  width: 90%;
  margin: 0px auto;
}

/* Profile */

.head-text {
  letter-spacing: 1.5px;
  line-height: 28px;
  font-size: 20px !important;
}

.icon-box {
  background-color: #ffffff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 0px !important;
  margin-right: 10px !important;
}

.subscriptionCard h3 {
  font-weight: 500;
  font-size: 16px;
}

.subscriptionCard p {
  font-weight: 500;
  font-size: 14px;
  color: var(--ion-color-muted);
}

.subscriptionCard h1 {
  text-align: center;
}

.displayProp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.primaryFont {
  color: var(--ion-color-primary);
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-bottom: 10px;
}

/* .detailBtnGrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
} */

.detailBtnGrp .btnICon {
  font-size: 25px;
  border-radius: 50%;
  background-color: #ef423b3b;
  padding: 4px 12px 7px 12px !important;
  width: fit-content;
  margin: auto;
}

.detailBtnGrp p {
  text-align: center;
  margin: 12px 0px;
  font-size: 12px;
  color: var(--ion-color-primary);
  font-weight: 700;
}

.displayPropAround {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  /* flex-wrap: wrap; */
}

.border-right {
  height: 50px;
  width: 1px;
  background: #e1e1e1;
}

ion-button {
  text-transform: capitalize;
}

.detailBody ion-button::part(native) {
  box-shadow: none;
  /* font-size: var(--font-size-12); */
  padding: 16px 8px;
  font-weight: var(--font-weight-500);
}

.accordionHeading {
  font-size: var(--font-size-15);
  font-weight: var(--font-weight-700);
}

.detailBody .datas .headText {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-700);
  color: var(--ion-color-muted);
}

.detailBody .datas .dataText {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-700);
  color: var(--ion-color-dark);
}

.interestBody ion-button::part(native) {
  box-shadow: none;
  /* font-size: var(--font-size-12); */
  font-weight: var(--font-weight-500);
}

/* profile */
.profileBody .topCard {
  background-color: var(--ion-color-primary);
  padding: 10px 25px 25px 25px;
  position: relative;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
  height: 420px;
}

.profileBody .outlineBtn {
  border: 1px solid #fff !important;
  border-radius: var(--border-radius-lighter);
  color: #fff;
  background: var(--ion-color-primary);
}

.profileBody ion-button::part(native) {
  box-shadow: none;
  padding: 8px;
  font-weight: var(--font-weight-500);
  border-radius: var(--border-radius-lighter);
}

.profileBody .bottomCard {
  border-radius: var(--border-radius-medium);
  box-shadow: 0px 16px 60px rgba(37, 37, 28, 0.15);
  margin: 0px;
  padding: 6px;
  background-color: #fff;
  margin-bottom: 50px !important;
}

@media (max-width: 575.98px) and (platform: ios) {
  .bottomCard {
    margin-bottom: 100px !important;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch !important;
  }
}

.profileBody .bottomCard .box {
  border: 1px solid var(--ion-color-primary);
  border-radius: 12px;
}

.profileBody ion-item::part(native) {
  --border-color: var(--ion-color-light);
  padding-left: 0px !important;
}

.profileBody img {
  width: 100px;
  height: 100px;
}

.profile-wid-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 320px;
}

@media (max-width: 575.98px) {
  .profile-wid-bg {
    height: 445px;
  }
}

.profile-wid-bg::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.9;
  background: red;
  background: linear-gradient(to top, #800000, red);
}

.profile-wid-bg .profile-wid-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-nav.nav-pills .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

.profile-nav.nav-pills .nav-link::before {
  background-color: rgba(255, 255, 255, 0.1);
}

.profile-project-card {
  border: 1px solid red;
  border-left: 3px solid red;
}

.user-profile-img {
  position: relative;
}

.user-profile-img .profile-img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.profileMainBody img {
  width: 46px;
  height: 46px !important;
}

@media (max-width: 991px) {
  .user-profile-img .profile-img {
    height: 100px;
  }
}

.user-profile-img .profile-foreground-img-file-input {
  display: none;
}

.user-profile-img .profile-photo-edit {
  cursor: pointer;
}

.profile-user {
  position: relative;
  display: inline-block;
}

.profile-user .profile-photo-edit {
  position: absolute;
  right: 0;
  left: auto;
  bottom: 0;
  cursor: pointer;
}

.profile-user .user-profile-image {
  object-fit: cover;
  border: 3px solid white;
  background: transparent;
}

.profile-user .profile-img-file-input {
  display: none;
}

/* .profile-timeline .accordion-item {
  position: relative;
} */

/* .profile-timeline .accordion-item .accordion-button {
  background-color: transparent;
} */

ion-accordion-group ion-item::part(native) {
  background-color: #f2f2f2;
}

.profile-timeline .accordion-item .accordion-button::after {
  background: none;
}

.profile-timeline .accordion-item::before {
  content: "";
  border-left: 2px dashed red;
  position: absolute;
  height: 100%;
  left: 23px;
}

.profile-timeline .accordion-item:first-child::before {
  top: 8px;
}

.profile-timeline .accordion-item:last-child::before {
  height: 20px;
  top: 3px;
}

.profile-setting-img {
  position: relative;
  height: 200px;
}

.profile-setting-img .overlay-content {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}

.profile-setting-img .profile-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.offerEnd {
  background-color: #f9ebd1;
  /* background-color: #FCF6F6; */
  display: flex;
}

.offerEnd h6 {
  color: black;
  font-weight: 600;
}

.offerEnd h2 {
  color: black;
  font-weight: 600;
}

.offerEnd h1 {
  color: red;
  font-weight: 600;
  padding-top: 3px;
}

.offerEnd ion-badge.md {
  margin: auto;
  margin-left: 8px;
  margin-right: 2px;
  padding-bottom: 0px;
  padding-top: 5px;
}

@media (max-width: 991.98px) {
  .profile-setting-img .profile-img {
    height: 160px;
  }
}

.profile-setting-img .profile-foreground-img-file-input {
  display: none;
}

.profile-setting-img .profile-photo-edit {
  cursor: pointer;
}

.avatar-title {
  padding: 7px 5px 0px 5px !important;
  background: white !important;
  border: 1px solid #e2e2e2 !important;
}

@media (max-width: 575.98px) {
  [data-layout="horizontal"] .profile-foreground {
    margin-top: 0 !important;
  }
}

button.calendar,
button.calendar:active {
  width: 2.75rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=) !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: 50%;
}

.golive {
  margin: 0 20px 0 20px;
  padding: 10 20px 20px 20px;
  border-radius: 20px;
  overflow: auto;
}

.floatBtn {
  position: fixed;
  bottom: 10px;
  right: 25px;
}

.floatBtn ion-button::part(native) {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-light);
  border-radius: var(--border-radius-largest);
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  padding: 12px 25px 12px 12px;
  text-transform: capitalize;
  font-size: var(--font-size-15);
  box-shadow: none;
  font-weight: var(--font-weight-600);
}

.userCard ion-button::part(native) {
  box-shadow: none;
}

.newUserCard ion-button::part(native) {
  box-shadow: none;
}

.userCard ion-button,
.userCard ion-button:disabled {
  --ripple-color: transprent !important;
}

.newUserCard ion-button,
.newUserCard ion-button:disabled {
  --ripple-color: transprent !important;
}

.test {
  margin-inline-start: -44px;
  bottom: 6px;
  background: var(--ion-color-dark-shade);
  border-radius: 50%;
  border: 6px solid #fff;
  z-index: 99;
}

.test ion-fab-button {
  border-radius: 50%;
  border: 6px solid rgb(255, 198, 205);
  height: 80px;
  width: 80px;
}

.goLiveIcon {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  border: 2px solid var(--ion-color-primary);
  background-color: var(--ion-color-primary);
}

.goLive {
  padding: 8px 9px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: block;
}

.NetWorkToast ion-toast::part(container) {
  margin: 0px 15px;
}

.alertToast {
  --border-radius: 12px !important;
  color: var(--ion-color-light) !important;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
}

.packageBody ion-content::part(background) {
  padding: 20px 30px !important;
}

.uploadOuter {
  text-align: center;
  padding: 20px;
}

.uploadOuter strong {
  padding: 0 10px;
}

.dragBox {
  width: 100%;
  height: 100px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: var(--font-weight-500);
  color: var(--ion-color-primary);
  border: 2px dashed #ccc;
  display: inline-block;
  transition: transform 0.3s;
  border-radius: 12px;
  font-size: var(--font-size-15);
  padding-top: 23px;
  line-height: 18px;
  letter-spacing: 0.3px;
}

.dragBox input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.draging {
  transform: scale(1.1);
}

#preview {
  text-align: center;
}

#preview img {
  max-width: 100%;
}

.fileName {
  font-size: 16px;
  font-weight: bold;
}

.userCard .userName {
  text-transform: capitalize;
  font-size: var(--font-size-15);
  font-weight: var(--font-weight-500);
  font-style: normal;
  /* margin-bottom: 5px !important; */
}

.newUserCard .NewUserName {
  text-transform: capitalize;
  font-size: var(--font-size-15);
  font-weight: var(--font-weight-500);
  font-style: normal;
  /* margin-bottom: 5px !important; */
}

.imagePreview {
  position: relative;
}

.transparentButton ion-button::part(native) {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  background-color: #fff !important;
  font-weight: var(--font-weight-600);
  color: black;
  border-radius: 10px;
  text-align: center;
  letter-spacing: 1px;
  padding: 22px 20px;
  text-transform: capitalize;
  font-size: var(--font-size-17);
}

.imagePreview .transparentBtn {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 6px;
  position: absolute;
  bottom: 5px;
  background-color: #fff;
  font-size: var(--font-size-12);
  height: 30px;
  font-weight: var(--font-weight-600);
}

.imagePreview .transparentBtnBiodata {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 6px;
  position: absolute;
  bottom: 25px;
  background-color: #fff;
  font-size: var(--font-size-12);
  height: 30px;
  font-weight: var(--font-weight-600);
}

.imagePreview .transparentBtnId {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 6px;
  position: absolute;
  bottom: 4px;
  background-color: #fff;
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-600);
  padding: 3px 10px;
  text-align: center;
}

.imagePreview .closeIcon {
  position: absolute;
  top: -5px;
  right: -5px;
  background: var(--ion-color-primary);
  padding: 2px;
  border-radius: 16px;
  color: #fff;
}

.imagePreview img {
  height: 140px !important;
  border-radius: 10px !important;
  width: 100% !important;
  object-fit: cover !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.imagePreview ion-button::part(native) {
  box-shadow: none;
}

.GoLiveImagePreview {
  position: relative;
}

.GoLiveImagePreview .closeIcon {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #ebebed;
  padding: 2px;
  border-radius: 16px;
  color: #000;
}

.GoLiveImagePreview img {
  border-radius: 10px !important;
  width: 100% !important;
  height: 102px !important;
  object-fit: cover !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.ion-page {
  background-color: var(--ion-color-light);
}

.myMenu .action-sheet-group.sc-ion-action-sheet-md:first-child {
  margin: 0px 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
}

.myMenu
  .action-sheet-group.sc-ion-action-sheet-md:first-child
  .action-sheet-button:first-child {
  border-bottom: 1px solid var(--ion-color-dark);
  color: #000;
}

.myMenu
  .action-sheet-group.sc-ion-action-sheet-md:last-child
  .action-sheet-button:last-child {
  color: #000;
}

.myMenu .action-sheet-group.sc-ion-action-sheet-md:last-child {
  margin: 10px 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
  opacity: 1;
}

.FooterPanel {
  border-top-left-radius: 30px;
  /* padding: 12px 0px 26px 0px; */
  box-shadow: 0px -4px 10px rgb(0 0 0 / 10%);
  border-top-right-radius: 30px;
  height: 75px;
}

.footerLabel {
  font-size: var(--font-size-12);
  font-weight: 600;
  margin-top: 7px;
  margin-bottom: 0px;
}

.footerIcon {
  line-height: 3px;
}

.wrapper {
  margin-bottom: 15%;
}

.line__1,
.line__2 {
  /* white-space: wrap; */
  overflow: hidden;
  margin: 0.5rem auto;
  font-size: 2em;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-top: 15vh;
}

.line__1 {
  /* define animation types for text + cursor */
  /* animation: animated-text__1 6s steps(30, end) 1s 1 normal both,
    animated-cursor__1 900ms steps(30, end) 8; */
}

.line__2 {
  color: var(--ion-color-primary);
  /* define animation types for text + cursor */
  animation: animated-text__2 5s steps(30, end) 1s 1 normal both,
    animated-cursor__2 900ms steps(30, end) infinite;
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}

@keyframes animated-cursor__1 {
  from {
    border-right-color: rgba(17, 17, 17, 0.9);
  }

  to {
    border-right-color: rgba(255, 255, 255, 0.8);
  }
}

@keyframes animated-cursor__2 {
  from {
    border-right-color: rgba(17, 17, 17, 0.9);
  }

  to {
    border-right-color: rgba(255, 255, 255, 0.8);
  }
}

@keyframes animated-text__1 {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes animated-text__2 {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

a {
  text-decoration: none !important;
}

.genderImg {
  padding: 20px 30px;
  /* background-color: #F0F2F5; */
  background-color: #fff0f1;
  border-radius: 15px;
  text-align: center;
}

.genderImg img {
  width: 72px;
  height: 90px;
  margin: 0 auto 10px auto;
}

.actionAlert2 {
  max-width: 400px;
}

.actionAlert2 .alert-title {
  font-size: 28px !important;
  line-height: 1.4 !important;
  text-align: center;
  background-color: var(--ion-color-primary);
  color: #ffffff;
}

.actionAlert2 .alert-head.sc-ion-alert-md {
  padding-top: 0px !important;
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
  text-align: center !important;
}

/* .alert-button-role-confirm {
  font-size: large !important;
} */

/* .alert-button-inner.sc-ion-alert-md {
  font-size: large;
} */

.actionAlert2 .alert-sub-title.sc-ion-alert-md {
  font-weight: bold !important;
  margin-top: 8px;
  margin-bottom: -12px;
}

.actionAlert2 .alert-message.sc-ion-alert-md {
  color: #252020 !important;
  padding-bottom: 4px !important;
}

.PayButton .alert-button {
  border-radius: 10px;
  width: 100% !important;
  max-width: 220px;
  margin: 0px;
  padding-inline-start: 0px;
}

.actionAlert2 .alert-message {
  text-align: center;
}

.PayButton .alert-button-group.sc-ion-alert-md {
  text-align: -webkit-center;
}

.actionAlert2 .alert-button-role-confirm {
  background: var(--ion-color-primary) !important;
  color: var(--ion-color-light) !important;
  width: 100% !important;
  text-align: center !important;
  letter-spacing: 1px !important;
  text-transform: capitalize !important;
  font-size: var(--font-size-20) !important;
  box-shadow: none !important;
  text-align: center !important;
  margin-bottom: 8px !important;
}

.alert-head.sc-ion-alert-md {
  text-align: center !important;
}

.firstpage {
  display: flex;
  position: absolute;
  bottom: 25px;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.firstslide {
  /* border: 1px solid var(--ion-color-primary); */
  height: 75vh;
  border-radius: 20px;
  width: 70vw;
  margin: 35px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

ion-input,
ion-textarea {
  --placeholder-color: var(--ion-color-muted) !important;
  color: var(--ion-color-muted);
  --placeholder-opacity: 1 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

ion-select,
.native-input,
textarea {
  --placeholder-color: lightgrey;
  --placeholder-opacity: 1 !important;
  color: var(--ion-color-muted) !important;
  font-size: 15px !important;
  padding-left: 15px !important;
  font-weight: 600 !important;
}

.primary-text {
  color: var(--ion-color-primary) !important;
}

.ellispses {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dashboardScreen .dragBoxDashboard {
  background: #fff;
  background: var(--ion-color-light);
  box-shadow: 0px 5px 50px rgb(0 0 0 / 12%);
  border-radius: var(--border-radius-medium) !important;
}

/* dashborad profile upload */
.dragBoxDashboard {
  width: 100%;
  min-width: 150px;
  height: 88px;
  position: relative;
  text-align: center;
  font-weight: var(--font-weight-500);
  color: var(--ion-color-primary);
  border: 2px dashed #ccc;
  display: inline-block;
  transition: transform 0.3s;
  border-radius: 12px;
  font-size: var(--font-size-14);
  padding-top: 23px;
  line-height: 15px;
  letter-spacing: 0.3px;
  margin: 4px;
}

.dragBoxDashboard input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

ion-accordion {
  margin-bottom: 10px;
  border-radius: 8px;
}

.alert-button-role-cancel {
  background: #f2f2f2 !important;
  color: #000 !important;
  width: 100% !important;
  text-align: center !important;
  letter-spacing: 1px !important;
  text-transform: capitalize !important;
  font-size: var(--font-size-16) !important;
  box-shadow: none !important;
  text-align: center !important;
}

.alert-button-group {
  display: block !important;
}

.alert-button-role-confirm {
  background: var(--ion-color-primary) !important;
  color: var(--ion-color-light) !important;
  width: 100% !important;
  border-radius: 12px !important;
  text-align: center !important;
  letter-spacing: 1px !important;
  text-transform: capitalize !important;
  font-size: var(--font-size-16) !important;
  box-shadow: none !important;
  text-align: center !important;
  margin-bottom: 8px !important;
}

.alert-button-inner {
  justify-content: center !important;
}

/* 
ion-datetime {
  width: 300px;
  max-width: 300px;
} */

.css-b62m3t-container {
  border-bottom: 1px solid black !important;
}

.css-641r35-control {
  border: none !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.css-1jqq78o-placeholder {
  color: var(--ion-color-muted) !important;
  --placeholder-color: var(--ion-color-muted) !important;
  --placeholder-opacity: 1 !important;
  /* font-weight: 600 !important; */
  font-size: 15px !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0px !important;
}

.css-lm8j94-menu {
  overflow-y: auto !important;
  overflow-y: scroll !important;
  background-color: white !important;
  z-index: 100 !important;
  opacity: 1 !important;
  color: #000;
  font-size: 16px !important;
}

.css-1dimb5e-singleValue {
  color: var(--ion-color-muted) !important;
}

.footerbtn-1 ion-button::part(native) {
  width: 11rem !important;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 15px !important;
}

.footerbtn-2 ion-button::part(native) {
  width: 11rem !important;
  border: 1px solid rgb(253, 253, 253);
  border-radius: 15px !important;
}

.item-radio-checked.sc-ion-select-popover-md {
  background-color: var(--ion-color-primary);
}

/* .item-radio-checked.sc-ion-select-popover-md .sc-ion-select-popover-md {
  color: #fff !important;

} */

.temp2 {
  margin: 0;
  /* height: 220px; */
  /* background: radial-gradient(#fff, #EF423B); */
  /* overflow-y: hidden; */
  -webkit-animation: fadeIn 1 1s ease-out;
  -moz-animation: fadeIn 1 1s ease-out;
  -o-animation: fadeIn 1 1s ease-out;
  animation: fadeIn 1 1s ease-out;
}

.temp2 img {
  border-radius: 0px;
}

@media (max-width: 850px) {
  .temp2 img {
    border-radius: 1rem;
    height: 9.5rem;
    margin-top: 0.3rem;
  }
}

@media (min-width: 1000px) {
  .temp2 img {
    height: 450px;
  }
}

@media (min-width: 768) and (max-width: 900px) {
  .temp2 img {
    border-radius: 1rem;
    height: 342px;
  }
}

.temp {
  margin: 0;
  height: 220px;
  font-weight: 100;
  background: radial-gradient(#ef423b, #fff);
  overflow-y: hidden;
  -webkit-animation: fadeIn 1 1s ease-out;
  -moz-animation: fadeIn 1 1s ease-out;
  -o-animation: fadeIn 1 1s ease-out;
  animation: fadeIn 1 1s ease-out;
}

.typewriter {
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid #fff;
  animation: cursor 1s step-start infinite, text 2s steps(18) alternate infinite;
}

/* .typewriter {
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid #fff;
  animation: cursor 1s step-start infinite,
    text 3s steps(18) alternate infinite;
} */

@keyframes cursor {
  0%,
  100% {
    border-color: #fff;
  }
}

@keyframes text {
  0% {
    width: 0;
  }

  100% {
    width: 21.5ch;
  }
}

/* .alert-head,
#alert-2-msg {
  text-align: center !important;
} */

/* ion-alert.custom-alert {
  --backdrop-opacity: 0.7;
  font-size: 15px;
} */

/* .custom-alert .alert-button-group {
  padding: 8px;
} */

/* button.alert-button.alert-button-confirm {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
} */

/* .md button.alert-button.alert-button-confirm {
  border-radius: 4px;
} */

/* .ios .custom-alert button.alert-button {
  border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2)
} */

/* .ios button.alert-button.alert-button-confirm {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
} */

.serachContainer ion-button::part(native) {
  box-shadow: none !important;
}

.onloading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background-color: #fff5f500;
}

.loading-container {
  position: relative;
}

/* The logo that will animate */
.loading-logo {
  width: 100px; /* Adjust the size as needed */
  z-index: 2;
  animation: scaleUpDown 2s infinite ease-in-out;
}

/* Circles */
.circle {
  position: absolute;
  border-radius: 50%;
  border: solid 3px red;
  animation: circleAnimation 3.2s linear infinite;
}

.circle-large {
  width: 120px;
  height: 120px;
  top: -10%;
  left: -13%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.circle-small {
  width: 100px;
  height: 100px;
  top: 4%;
  left: -6%;
  transform: translate(-50%, -50%);
  z-index: 0;
  animation-duration: 2.8s;
}

/* Define the scaling animation for the logo */
@keyframes scaleUpDown {
  0%,
  100% {
    transform: scale(1);
    /* opacity: 1; */
  }
  50% {
    transform: scale(1.2);
    /* opacity: 0.8; */
  }
}

/* Define the animation for the circles */
@keyframes circleAnimation {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 0.25;
    border-radius: 25%;
  }
  50% {
    transform: scale(1.2) rotate(270deg);
    opacity: 1;
    border-radius: 50%;
  }
}

ion-accordion::part(header) {
  opacity: 1 !important;
}

.card-family {
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 10px;
  margin: 20px 0px 0px 0px;
}

.card-family-name {
  position: absolute;
  margin: -22px 0px;
  background: #f7f7f7;
  color: #640000;
  padding: 0px 5px;
}

ion-searchbar input {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px !important;
  background-color: #fff;
}

@supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or
  (-webkit-margin-start: 0) {
  .item-inner {
    padding-right: 0px !important;
  }
}

.filterBtn ion-button::part(native) {
  font-size: 12px !important;
  padding: 4px 6px !important;
  border: 1px solid !important;
}

.search-input {
  border-radius: 100px;
  position: relative;
  padding: 0px 10px 0px 20px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  display: flex;
  justify-content: space-between;
}

.search-input ion-button::part(native) {
  border-radius: 50% !important;
  padding: 0px !important;
}

.passBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 400px;
  border-bottom: 0.5px solid #989aa2;
}

.passBtn ion-button::part(native) {
  border-radius: 50%;
  padding: 2px !important;
}

.alert-message {
  margin: auto;
  padding-top: 5px !important;
}

.alert-message img {
  border-radius: 15px;
  height: 160px;
  width: 160px;
  object-fit: cover;
  margin: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* .zind {
  z-index: 999;
} */

.educationModal ion-item ion-label {
  text-overflow: unset !important;
  white-space: inherit !important;
}

.ageModal ion-item ion-label {
  text-overflow: unset !important;
  white-space: inherit !important;
}

ion-toolbar ion-title {
  font-size: 18px !important;
}

/* .rmdp-input {
  border-bottom: 1px solid #c0c4d6 !important;
  height: 22px;
  margin: 1px 0;
  padding: 20px 10px 20px 2px !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0px !important;
} */

/* .rmdp-week-day {
  color: var(--ion-color-primary) !important;
  font-weight: 600 !important;
} */

/* .rmdp-arrow {
  border-color: var(--ion-color-primary) !important;
} */

/* .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--ion-color-primary) !important;
} */

/* .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #ef423bc2 !important;
} */

/* .rmdp-action-button {
  color: var(--ion-color-primary) !important;
} */

/* .rmdp-arrow-container:hover {
  background-color: #fff !important;

} */

/* .rmdp-day-picker {
  display: block !important;
} */

/* .rmdp-calendar-container-mobile .rmdp-mobile.rmdp-wrapper {
  max-width: 400px !important;
  width: 100% !important;
} */

/* .rmdp-top-class {
  display: block !important;
} */

/* .text-capitalise {
  text-transform: capitalize;
} */

.help {
  color: var(--ion-color-primary);
  font-size: 14px;
}

/* new css */
.detailSlides {
  width: 100%;
}

.detailSlidesMain {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.detailSlides::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -74px 79px -23px #111 inset;
}

.progress-bar-text {
  font-weight: 700;
  font-size: 14px;
}

.profile-number {
  font-size: 13px;
}

/* .advanceBtn {
  font-size: 16px;
  background: #ef423b29;
  border-radius: 10px;
  font-weight: 700;
} */

.test ion-fab-button::part(native) {
  bottom: 0.2px;
  box-shadow: none;
}

/* .rmdp-header {
  height: 40px !important;
} */

.badgess {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1px 2px 1px 15px;
  color: #fff;
  font-weight: 700;
  right: -7px;
}

.badgess--sales {
  position: relative;
  display: inline-block;
  background-color: var(--ion-color-primary);
  width: 35%;
  height: 36px;
  line-height: 2.2rem;
}

.badgess--sales::before {
  position: absolute;
  left: -17px;
  top: -1px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-right: 18px solid var(--ion-color-primary);
  border-bottom: 20px solid transparent;
}

.badgess--sales::after {
  display: block;
  content: "";
  position: absolute;
  background: white;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  left: -4px;
  top: calc(100% / 2 - 5px);
}

.in-middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}

rct-title {
  margin: 6px !important;
}

li.rct-node.rct-node-parent.rct-node-expanded {
  list-style-type: none;
  /* padding: 5px 15px; */
  /* margin-left: 12px; */
}

.checkbox-tree-education {
  margin-left: 12px;
}

li.rct-node.rct-node-parent.rct-node-collapsed {
  list-style-type: none;
  inline-size: max-content;
}

li.rct-node.rct-node-leaf {
  list-style-type: none;
}

.checkbox-tree-education label {
  padding: 6px 0px;
}

button.rct-collapse.rct-collapse-btn {
  height: 19px;
  padding: 0px 2px 0px 2px;
  margin-right: 5px !important;
  border-radius: 2px;
}

.checkbox-tree-container label {
  display: block !important;
  padding: 6px 0px;
  margin-left: 12px;
}

span.rct-title {
  font-size: 16px;
  margin-left: 10px;
}

ol {
  margin: 0px !important;
  padding: 0px !important;
}

.checkbox-tree-education button.rct-collapse.rct-collapse-btn {
  display: none;
}

.checkbox-tree-container button.rct-collapse.rct-collapse-btn {
  display: none;
}

/* .ion-color-primary {
  color: white !important;
} */

/* .ion-delegate-host {
  width: 240px
} */

.right-opening-modal {
  --width: 250px;
  --translateX: calc(149% - var(--width));
  transform: translateY(0%) translateX(var(--translateX));
  width: var(--width);
}

textarea.native-textarea.sc-ion-textarea-md {
  background-color: white;
  border-radius: 6px;
}

ion-list.md.list-md {
  background-color: #f7f7f7;
}

.parent-option {
  color: black;
  font-weight: 600;
}

.child-option {
  color: black;
  font-weight: 100;
}

.displayPackage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

ion-badge.ion-color.ion-color-success.md {
  font-size: larger;
}

ion-col.md {
  padding: inherit;
}

.subscriptionCard ion-item::part(native) {
  --border-style: none;
  background-color: blue !important;
}

.offerEndTime {
  margin: 3px;
  font-size: inherit;
}

.offerEndTimedot {
  color: #000;
  place-self: center;
  margin-left: 2px;
  margin-right: -3px;
}

.packagebtn ion-button::part(native) {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-light) !important;
  border-radius: 10px;
  width: 97px;
  text-align: center;
  text-transform: capitalize;
  font-size: var(--font-size-17);
  font-weight: var(--font-weight-600);
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .authbottomCover {
    position: absolute;
    bottom: 0px;
    border-top-left-radius: var(--border-radius-largest);
    border-top-right-radius: var(--border-radius-largest);
    width: 100%;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    background-color: #fff;
  }

  .web-logo {
    display: none;
  }

  .home-body {
    margin-top: 0rem;
  }
}

.referInput {
  background-color: #fff;
  color: #000;
  border-radius: 12px;
}

.referInput input {
  padding: 12px !important;
  text-align: initial !important;
}

.referSec {
  position: relative;
  /* width: 100%; */
  margin-right: 10px;
}

.referImg img {
  max-width: 225px;
  border-radius: 15px;
  margin: auto;
}

.referSec ion-button {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
}

.bottomCard ion-tab-button::part(native) {
  font-size: 14px;
  font-weight: 700;
}

.bottomCard ion-tab-bar {
  border: none !important;
}

.bottomCard ion-tab-button {
  max-width: 100%;
}

/* .ReactModal__Overlay--after-open {
  background: #000 !important;
} */

.shareBtn ion-button::part(native) {
  background-color: #1c274f !important;
  padding: 15px 20px !important;
}

/* .referral_input input {
  padding-top: 0px !important;
  --placeholder-color: black !important;
  font-size: 14px !important;
  --placeholder-opacity: 0.6 !important;
  color: #000 !important;

} */

.blinkText {
  font-size: 14px !important;
  font-weight: 700 !important;
  animation: blinker 1.2s infinite;
  color: var(--ion-color-primary);
  cursor: pointer;
}

/* @keyframes blinker {
  50% {
    opacity: 0.5;
  }
} */

.hr-border-red-dark {
  height: 1px;
  width: 100%;
  margin-top: -18px;
  background: linear-gradient(
    to right,
    #000 0%,
    red 26%,
    red 52%,
    red 75%,
    #000 100%
  );
  margin: 2.5px 0px;
}

.hr-border-yellow-dark {
  height: 1px;
  width: 100%;
  margin-top: -18px;
  background: linear-gradient(
    to right,
    #000 0%,
    #ffcc00 26%,
    #ffcc00 52%,
    #ffcc00 75%,
    #000 100%
  );
  margin: 2.5px 0px;
}

.hr-border-green-dark {
  height: 1px;
  width: 100%;
  margin-top: -18px;
  background: linear-gradient(
    to right,
    #000 0%,
    green 26%,
    green 52%,
    green 75%,
    #000 100%
  );
  margin: 2.5px 0px;
}

.hr-border-blue-dark {
  height: 1px;
  width: 100%;
  margin-top: -18px;
  background: linear-gradient(
    to right,
    #000 0%,
    blue 26%,
    blue 52%,
    blue 75%,
    #000 100%
  );
  margin: 2.5px 0px;
}

.hr-text-dark {
  margin-top: -25px !important;
  width: 150px !important;
  background: #000;
  color: #fff;
  margin: auto;
  padding: 5px !important;
}

.gradient {
  font-size: 20px;
  font-weight: 700 !important;
  background: repeating-linear-gradient(
    to right,
    red 10%,
    yellow 30%,
    green 30%,
    blue 100%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  padding-left: 10px !important;
}

.helpSec ion-item::part(native) {
  height: 45px !important;
  min-height: 35px !important;
}

.subscriptionDesc ul {
  margin-bottom: 0 !important;
}

.advanced-Btn {
  color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}

.corner-ribbon {
  position: absolute;
  top: -3px;
  left: -3px;
  height: 5.5em;
  width: 5.5em;
  padding: 8px;
}

.corner-ribbon .cr-inner {
  position: absolute;
  inset: 0;
  background: green;
  color: white;
  border-radius: 16px 8px 0 8px;
  clip-path: polygon(0 0, 100% 0, 0 100%);
}

.corner-ribbon .cr-text {
  display: block;
  font-size: 0.8em;
  line-height: 1.3;
  transform: rotate(-45deg) translateY(-0.2em) translateX(-1.6em);
  font-weight: 700 !important;
  text-align: center;
}

.img-profile {
  max-width: 100%;
  height: 100% !important;
}

.corner-ribbon::before,
.corner-ribbon::after {
  content: "";
  position: absolute;
  background: green;
  z-index: -1;
}

.corner-ribbon::before {
  top: calc(100% - 8px);
  left: 0;
  height: 8px;
  width: 3px;
  border-radius: 0 0 0 50%;
}

.corner-ribbon::after {
  left: calc(100% - 8px);
  top: 0;
  width: 8px;
  height: 3px;
  border-radius: 0 50% 0 0;
}

.btn-group button {
  font-size: 12px !important;
  font-weight: 700;
  border: 1px solid #13653f;
  padding: 0px 10px !important;
  max-height: 35px;
}

.notifictaionText {
  text-overflow: unset !important;
  white-space: inherit !important;
  overflow: inherit !important;
}

ion-range::part(pin) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ef423b;
  color: #fff;
  border-radius: 50%;
  transform: scale(1.01);
  top: -20px;
  min-width: 28px;
  height: 28px;
  transition: transform 120ms ease, background 120ms ease;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  accent-color: red;
}

.filterBackGround ion-content::part(background) {
  --background: #f7f7f7;
}

.custom-placeholder::placeholder {
  /* Add your desired styles here */
  margin-left: 2px !important;
}

.Toastify__toast-theme--light {
  background-color: green !important;
  text-align: center !important;
  color: var(--ion-color-light) !important;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
}

.Toastify__toast-container {
  padding: 2px !important;
}

.Toastify__close-button {
  display: none !important;
}

.offerCard ion-item::part(native) {
  background-color: #ef423b;
}

.specialOfferCard ion-item::part(native) {
  background: linear-gradient(to right, #ffdcb2, #fffdb2);
}

ion-icon.fs-18.md.ion-color.ion-color-primary.icon-large.hydrated {
  margin-top: 7px;
}

.discountCard {
  width: 266px;
  height: 36px;
  background-color: #cdf7d2;
  transform: skew(-30deg);
  text-align: center;
  border-radius: 0%;
  justify-content: center;
}

.discountCard p {
  font-weight: 600;
  font-size: 18px;
  color: #008623;
  align-items: center;
  transform: skew(20deg);
}

.discountCardcontainer {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-bottom: -9px;
}

.slide-container {
  height: 100px;
}

.plan {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-plan {
  width: 100px;
  height: 200px;
  background-color: #efefef;
}

.large-plan {
  width: 200px;
  height: 300px;
  background-color: #ccc;
}

.planCard {
  background: linear-gradient(to right, #ff8500, #ffff00);
  padding: 4px !important;
}

.custom-btn-pushnotification {
  background: #dfe3fc !important;
  color: blue !important;
  border-radius: 5px;
}

.custom-red-button ion-button::part(native) {
  background: #dfe3fc !important;
  color: #2d40e9 !important;
  margin-top: 0.5em;
  /* border-radius: 30% !important; */
}

.image-newCard ion-img::part(image) {
  border-radius: 1em 1em 0 1em !important;
}

.favouriteBtn ion-button::part(native) {
  color: #fff;
  padding: 1em 1em;
  max-width: 100%;
  margin-left: 0.5em;
}

.favouriteBtnIcon {
  transform: rotate(0deg);
  margin-top: -2px;
  margin-left: -3px;
  margin-right: 2px;
  font-size: 1em;
}

.white-New {
  /* color: var(--ion-color-light); */
  transform: rotate(315deg);
  margin-top: -4px;
  margin-left: -3px;
  margin-right: 2px;
  font-size: 1em;
}

@media (min-width: 768px) {
  .favouriteBtn ion-button::part(native),
  .sendInterestBtn ion-button::part(native) {
    max-width: 98%;
    width: 100%;
    margin-left: 0;
    font-size: 1em;
  }
}

.corner-ribbon-new {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.8em;
  width: 2.8em;
  padding: 2px;
}

.corner-ribbon-new .cr-inner-new {
  position: absolute;
  inset: 0;
  background: green;
  color: white;
  border-radius: 12px 5px 0 5px;
  clip-path: polygon(0 0, 100% 0, 0 100%);
}

.corner-ribbon-new .cr-text-new {
  display: block;
  font-size: 0.4em;
  line-height: 0.6;
  transform: rotate(-45deg) translateY(-0.2em) translateX(-1.6em);
  font-weight: 500 !important;
  text-align: center;
}

.usersOptions {
  position: absolute;
  right: 1%;
  top: 1%;
}

/* .sendInterestBtn ion-button::part(native) {
  max-width: 7rem;
} */

.PkgModalBtn ion-button::part(native) {
  height: 4em;
  position: fixed;
  top: 34%;
}

.filterbtn {
  position: fixed !important;
  top: 79vh !important;
  display: flex;
  justify-content: center;
  height: 10px;
  width: 100%;
  z-index: 99999 !important;
}

.filterbtn ion-button::part(native) {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-light);
  border-radius: 10px;
  text-align: center;
  letter-spacing: 1px;
  padding: 18px 8px;
  text-transform: capitalize;
  font-size: var(--font-size-16);
  box-shadow: none;
}

/* Rohit CSS */
@media (min-width: 768px) {
  .filterbtn {
    display: none;
  }
}

@media (max-width: 768px) {
  .popover-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .optionParent ion-item::part(native) {
    min-height: 29px !important;
  }

  .newusercardpopover > ion-popover::part(content) {
    width: 35vw !important;
  }

  .BirthDatePicker > ion-popover::part(content) {
    width: 88vw;
    max-height: 500px;
  }
}

.otp-submit-button ion-button::part(native) {
  padding: 2em !important;
  border-radius: 10px !important;
  margin-bottom: 24px !important;
}

.otp-submit-button {
  margin-bottom: 24px !important;
}

.PayButtonSplPkg {
  border-radius: 5px;
  font-weight: 600;
  background-color: #f31f3a;
  color: white;
  height: 1.9em;
  padding-left: 2.2em;
  padding-right: 2.2em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  width: 100% !important;
  position: relative;
  right: -8%;
  bottom: 4px;
}

.custom-slides {
  .swiper-pagination {
    bottom: -6px;
  }
}
.custom-slides2 {
  .swiper-pagination {
    bottom: -7px;
  }
}

.custom-card {
  border-radius: 11px;
  height: 11em;
  border: 1px solid red;
  width: 100%;
}

.CardBox {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(227, 109, 23, 1) 0%,
    rgba(253, 206, 0, 1) 100%
  );
  height: 5em;
  text-align: center;
  width: 100%;
  padding-top: 3px;
}

.custom-card-timeroff {
  border-radius: 11px;
  height: 9em;
  border: 1px solid red;
  width: 100%;
}

.CardBox-timeroff {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(227, 109, 23, 1) 0%,
    rgba(253, 206, 0, 1) 100%
  );
  height: 5.5em;
  text-align: center;
  width: 100%;
  padding-top: 3px;
  display: grid;
  align-items: center;
}

.CardBox-autoPkg {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(227, 109, 23, 1) 0%,
    rgba(253, 206, 0, 1) 100%
  );
  height: 5.8em;
  text-align: center;
  width: 100%;
  padding-top: 3px;
}

.SplImage-timeroff,
.SplImage-timeroff ion-img::part(image) {
  width: 100%;
  object-fit: cover !important;
  height: 9em !important;
  object-position: center !important;
}

.CardBox-title-1-timeroff {
  margin-top: 6px;
  font-size: 1.2em;
  line-height: 0.9;
  font-weight: 500;
}

.CardBox-title-2-timeroff {
  font-size: 1.2em;
  line-height: 0.9;
  font-weight: 600;
}

.CardBox-title-2-autoPkg {
  font-size: 1.2em;
  line-height: 1;
  font-weight: 600;
}

.CardBox-title-3-timeroff {
  font-size: 1em;
  margin-left: 4px;
  font-weight: 100;
}

.CardBox-title-4-timeroff {
  font-size: 0.8em;
  line-height: 1;
}

.CardBox-title-4-autoOffer {
  font-size: 0.8em;
  /* line-height: ; */
}

.review-alert {
  min-height: 70vh !important;
  min-width: 80vw !important;
}

.PayButtonSplPkg-timeroff {
  border-radius: 3px;
  font-weight: 600;
  background-color: #f31f3a !important;
  color: white !important;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.btnPay-timeroff {
  margin-top: 3% !important;
  width: 81% !important;
  padding: 0;
}
.btnPay-timeroff-new {
  margin-top: 3% !important;
  width: 100% !important;
  padding: 0 !important;
}

@media screen and (min-width: 1440px) and (max-width: 2560px) {
  .newusercardpopover ion-popover::part(content) {
    width: fit-content !important;
    /* left: 59% !important; */
    max-height: 500px;
  }
}

.timeDisplay {
  background-color: #f31f3a;
  color: #fff;
  padding: 5px;
  font-weight: 600;
  /* font-height: 13px; */
  width: 25px;
  border-radius: 5px;
}

.timerContainer {
  font-size: 0.8em;
  padding: 0;
  color: #f31f3a;
  font-weight: 600;
  margin-left: 2px;
  margin-right: 2px;
}

.CardBox-title-1 {
  margin-top: 5px;
  font-size: 1em;
  line-height: 1;
  font-weight: 500;
}

.CardBox-title-2 {
  font-size: 1em;
  line-height: 1;
  font-weight: 600;
}

.CardBox-title-3 {
  font-size: 0.9em;
  margin-left: 4px;
  font-weight: 100;
}

.CardBox-title-4 {
  font-size: 0.7em;
  line-height: 1;
}

.SplImage ion-img::part(image) {
  width: 100%;
  object-fit: cover;
  height: 11em;
}

.btnPay {
  margin-top: 0.5em !important;
}

.actionAlert123 .alert-button-group.sc-ion-alert-md {
  display: flex !important;
  justify-content: space-around;
  flex-direction: row !important;
}

.actionAlert123 .alert-button-role-cancel {
  width: fit-content !important;
}

.actionAlert123
  button.alert-button.ion-focusable.ion-activatable.alertButton.sc-ion-alert-md {
  background-color: var(--ion-color-primary, #3880ff);
  color: white;
  padding-top: 5px;
  padding-bottom: 5px !important;
  padding-right: 35px;
  padding-left: 35px;
}

.actionAlert123 .ion-alert-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.actionAlertOption .ion-alert-buttons {
  color: green !important;
}

/* website css rohit  */
@media (min-width: 1024px) {
  .ionSlidesCustom {
    margin-top: 0 !important;
  }

  .custom-card {
    width: 59%;
    height: 16em !important;
  }

  .CardBox {
    height: 7.8em;
    padding-top: 2em;
  }

  .PayButtonSplPkg {
    border-radius: 5px;
    font-weight: 600;
    background-color: #f31f3a !important;
    color: white !important;
    padding-left: 9em;
    padding-right: 9em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .btnPay {
    margin-top: 1em !important;
  }

  .CardBox-title-1 {
    font-size: 1.5em;
    line-height: 1;
    font-weight: 500;
  }

  .CardBox-title-2 {
    font-size: 1.2em;
    line-height: 1;
    font-weight: 600;
  }

  .CardBox-title-3 {
    font-size: 0.9em;
    margin-left: 4px;
    font-weight: 100;
  }

  .CardBox-title-4 {
    font-size: 1em;
    line-height: 1;
  }

  .timeDisplay {
    background-color: #f31f3a;
    color: #fff;
    padding: 0.3em;
    font-weight: 600;
    /* font-height: 13px; */
    width: 30px;
    border-radius: 5px;
  }

  .timerContainer {
    font-size: 1em;
    padding: 0;
    color: #f31f3a;
    font-weight: 600;
  }

  .SplImage ion-img::part(image) {
    width: 100%;
    object-fit: cover;
    height: 16em;
  }

  .custom-card-timeroff {
    border-radius: 11px;
    height: 11em;
    width: 59%;
    border: 1px solid red;
  }

  .CardBox-timeroff {
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(227, 109, 23, 1) 0%,
      rgba(253, 206, 0, 1) 100%
    );
    height: 6.5em;
    text-align: center;
    width: 100%;
    padding-top: 3px;
    display: grid;
    align-items: center;
  }

  .SplImage-timeroff,
  .SplImage-timeroff ion-img::part(image) {
    width: 100%;
    object-fit: cover !important;
    height: 11em !important;
    object-position: center !important;
  }

  .CardBox-title-1-timeroff {
    font-size: 1.5em;
    line-height: 1;
    font-weight: 500;
  }

  .CardBox-title-2-timeroff {
    font-size: 1.5em;
    line-height: 1;
    font-weight: 600;
  }

  .CardBox-title-3-timeroff {
    font-size: 1.2em;
    margin-left: 4px;
    font-weight: 100;
  }

  .CardBox-title-4-timeroff {
    font-size: 0.9em;
    line-height: 1;
  }

  .PayButtonSplPkg-timeroff {
    border-radius: 3px;
    font-weight: 600;
    background-color: #f31f3a !important;
    color: white !important;
    padding-left: 9.5em;
    padding-right: 9.5em;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }

  .btnPay-timeroff {
    margin-top: 1.2em !important;
    width: 100% !important;
    padding: 0;
  }

  .newusercardpopover ion-popover::part(content) {
    width: 34vw !important;
    /* left: 59% !important; */
    top: 25% !important;
  }

  .BirthDatePicker ion-popover::part(content) {
    width: 88vw;
    /* left: 32px; */
    max-height: 500px;
  }
}

/* user card media  */

.subInfoUserCard {
  letter-spacing: 0.1px !important;
  font-size: 0.9em !important;
  line-height: 15px !important;
}

.userCardNameandAge {
  display: flex !important;
  font-size: 1em !important;
  flex-wrap: wrap;
  line-height: 20px !important;
  color: #000 !important;
}

.manglikAndAddressUserCard {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

@media (min-width: 561px) and (max-width: 990px) {
  .manglikAndAddressUserCard {
    flex-direction: column !important;
  }
  .block-ad-md {
    display: none !important;
  }
  .CardImage {
    height: 14.3em !important;
  }
  .userCardNameandAge .cardAge {
    font-size: 8px !important;
  }
}

.manglikUserCard {
  letter-spacing: 0.1px !important;
  font-size: clamp(0.3em, 1vw + 0.5em, 0.8em) !important;
  font-weight: 600 !important;
  color: #eb1d25 !important;
}

.manglikValueUserCard {
  letter-spacing: 0 !important;
  font-size: clamp(0.3em, 1vw + 0.5em, 0.8em) !important;
  color: #000 !important;
  line-height: 0.8 !important;
  text-transform: capitalize !important;
  padding-left: 16px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.addressUserCard {
  letter-spacing: 0.1px !important;
  font-size: clamp(0.3em, 1vw + 0.5em, 0.8em) !important;
  font-weight: 600 !important;
  color: #eb1d25 !important;
}

.addressValueUserCard {
  letter-spacing: 0.1px !important;
  font-size: clamp(0.3em, 1vw + 0.5em, 0.8em) !important;
  color: #000 !important;
  line-height: 0.8 !important;
  padding-left: 16px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  min-width: 80px !important;
}

.pkgWarning {
  margin-top: -71px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pkgWarning > p {
  font-size: 0.7em !important;
  color: #640000 !important;
  font-weight: 600;
  margin-bottom: 0 !important;
}

.pkgWarning > .upgradeBtn {
  color: #ef423b;
  font-weight: 600;
  text-decoration: underline;
}

@media screen and (max-width: 348px) {
  .subInfoUserCard {
    font-size: 0.7em !important;
  }
  .userCardNameandAge {
    font-size: 0.8em !important;
  }

  .manglikUserCard {
    font-size: 0.7em !important;
  }

  .addressUserCard {
    font-size: 0.7em !important;
  }
  .pkgWarning {
    margin-top: -36px !important;
  }
}

@media (min-width: 1024px) {
  .pkgWarning {
    margin-top: -100px !important;
  }
}

.payment-callback-content {
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
  flex-direction: column;
}

.success-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.failed-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success-message > .success-icon {
  background: #008623 !important;
  color: white !important;
  padding: 25px;
  border-radius: 50%;
  font-size: 2em;
  margin-bottom: 10px;
}
.failed-message > .failed-icon {
  background: #f10707 !important;
  color: white !important;
  padding: 25px;
  border-radius: 50%;
  font-size: 2em;
  margin-bottom: 10px;
}

.edit-btn ion-button::part(native) {
  padding: 1rem;
}

@media (min-width: 768px) {
  .slides-height-md-lg {
    height: 240px;
  }
}

.topLinks.active {
  color: #ef423b;
}

.form-list {
  height: 100vh;
}

.privateBtn {
  position: absolute;
  left: 57px;
  top: 10px;
  z-index: 9999;
}

.private-alert .alert-wrapper {
  border-radius: 12px; 
}

.private-alert .alert-button {
  width: 100%;
  text-align: center;
  background-color: #ef423b; 
  color: white;
  font-weight: bold;
  border-radius: 8px; 
  padding: 12px 0;
}
.mainCard {

    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    position: relative;
    height: -webkit-fill-available;
    width: 100%;
    /* background-color: #f2f2f2; */
}

/* .mainCard h1 {
    color: var(--ion-color-success);
    font-weight: 700;
} */

ion-modal#example-modal-subscribe {
    --max-width: 330px;
    --max-height: 610px;
    --border-radius: 6px;
    --box-shadow: none;
    padding: 10px;
}

/* ion-modal#example-modal-subscribe2 {
    --max-width: 380px;
    --max-height: 400px;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    padding: 10px;
} */

ion-modal#example-modal-subscribe1 {
    --max-width: 380px;
    --max-height: 240px;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    padding: 10px;
}
/* .mainCard .subscribeLogo {
    width: 230px;
    margin: 0 auto 20px auto;
} */

.btn-primary {
    font-size: 15px !important;
    font-weight: 600 !important;
    background-color: var(--ion-color-primary) !important;
    border: none !important;
}

.mainCard .textSub {
    color: var(--ion-color-muted);
    font-weight: 500;
}

ion-modal#example-modal-subscribe::part(content) {
    --background: none !important;
    --backdrop-opacity: 0.8;
}

ion-modal#example-modal-subscribe::part(backdrop) {
    --backdrop-opacity: 0.7;
}

.popUpBtn {
    position: absolute;
    font-size: 16px !important;
    font-weight: 700 !important;
    position: absolute;

}
ion-modal#example-modalsss::part(backdrop) {
    --backdrop-opacity: 0.9;
}

ion-modal#example-modalsss::part(content) {
    height: 100% !important;
    background: transparent !important;
}

.downloadBtn {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 999;

}
.downloadBtn .blinkDownloadBtn {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    font-size: 15px !important;
    text-align: center;
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
    font-weight: 700 !important;
    margin-right: 10px;
    padding: 8px;
}
.helpBtn {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    font-size: 18px !important;
    text-align: center;
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
    font-weight: 700 !important;
    margin-right: 10px;
    padding: 10px 20px;
}

@keyframes glowing {
    0% {
        background-color: #B20000;
        box-shadow: 0 0 1px #B20000;
    }

    50% {
        background-color: #FF0000;
        box-shadow: 0 0 50px #FF0000;
    }

    100% {
        background-color: #B20000;
        box-shadow: 0 0 1px #B20000;
    }
}